import 'lazysizes/lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/respimg/ls.respimg';

import "../js/magnificpopup";
import "../js/bootstrap";
import "../js/fontawesome";
import "../js/glide";

import UIBindings        from "./ui-bindings";
import { Forms }         from "./form";
import { ExternalLinks } from "./externallinks";
import { PageTimer }     from "./pagetimer";
import { ShareHandler }  from "./share";

(new PageTimer()).logToConsole();
(new ExternalLinks('body')).addLinkTarget();
(new ShareHandler()).build();

const forms = new Forms();
forms.validate();


const UIBinding = new UIBindings();
UIBinding.formSubmitButton();


// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}

$(function() {
    // $(window).on("scroll", function() {
    //   if ($(window).scrollTop() >= 20) { 
    //     $('#mainNavigtion').addClass('fixed-top');
    //   } else {
    //     $('#mainNavigtion').removeClass('fixed-top');
    //   }
    // });


    $( ".cls-world-1" ).click(function() {
      $( "#internationalContact").removeClass('d-none');
      $( "#internationalContact").addClass('d-block');
    });

    $( ".cls-world-1-main" ).click(function() {
      window.location.href = '/contact?country=Global';
    });

    

    $( "#closeContact" ).click(function() {
      $( "#internationalContact").addClass('d-none');
      $( "#internationalContact").removeClass('d-block');
    });
  });


  